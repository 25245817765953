<template>
    <div class="action-block">
        <v-popover trigger='hover' placement="bottom" :popoverClass="className" offset="-10">
            <fa ref="actions-icon" :icon="['fal','ellipsis-h']" class="ml-auto text-4xl cursor-pointer" />

            <template slot="popover">
                <div ref="invoiseAction" class="absolute top-0 right-0 z-100 mt-8 bg-white border rounded select-none min-w-67" v-closable="{
                    handler: 'close',
                    exclude: ['actions-icon'],
                }">
                    <div v-for="action in actions" :key="action.id">
                        <div
                            v-if="showAction(action)"
                            class="flex px-5 py-3"
                            @click.stop="emitClick(action)"
                            :class="{ 'bg-gray-200 text-gray-500 cursor-not-allowed': !action.active, 'cursor-pointer hover:bg-gray-100': action.active }">
                            <div class="w-4">
                                <fa :icon="['fal', action.icon]" class="text-lg" />
                            </div>
                            <p class="ml-2">{{ action.label }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>

    import { VPopover } from 'v-tooltip';


    export default {

        name: 'InvoiceControlActions',

        props: {
            actions: {
                required: true,
                type: Array
            },
            single: {
                required: false,
                default: true,
                type: Boolean,
            },
            tableRef: {
                required: false,
                default: '',
                type: String,
            },
            className: {
                type: String,
            }
        },

        data() {
            return {
                show: false,
            }
        },

        computed: {
            containerHeight() {
                const itemHeight = 43;
                return (itemHeight * this.actions.length) + 50
            }
        },
        created() {

        },
        mounted() {
            if (this.tableRef) {
                const table = this.findRefByName(this.tableRef)
                table.setOverflowMargin(this.containerHeight)
            }
        },
        methods: {
            showAction(action) {
                if (! this.single && ! action.single_only) {
                    return true;
                }

                if (this.single) {
                    return true;
                }

                return false;
            },

            toggleShow() {
                this.show = !this.show;
            },

            close() {
                this.show = false;
            },

            emitClick(action) {

                if (! action.active) {
                    this.$emit('action-blocked', action.id)
                    this.close();
                    return;
                }

                this.$emit('action-go', action.id)
                this.toggleShow();
            },
        },

        components: {
            VPopover,
        }
    }
</script>
